

































































































import { debounceInput } from '@/shared/helpers'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import vehicleService from '@/shared/services/vehicleService'
import { IVehicle, IVehicleDetails, Vehicle } from '@/shared/model/vehicle.model'
import { IVehicleManufacturer } from '@/shared/model/vehicleManufacturer.model'

// @ts-ignore
import { VueEditor } from 'vue2-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

export default Vue.extend({
  name: 'VehicleWizard',
  components: {
    VueEditor,
  },

  props: {
    selectedVehicle: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      options: {} as any,
      optionsManufacturers: {} as any,

      dialog: false,

      vehicleManufacturerSearch: '',
      vehicleManufacturerDebounce: undefined as number | undefined,
      vehicleDebounce: undefined as number | undefined,

      vehicle: new Vehicle(),
      vehicleDetails: { pdfs: [], content: '' } as IVehicleDetails,
      vehicleAutocomplete: '',

      vehicleManufacturers: [],
      vehicleManufacturerAutocomplete: '',

      selectedVehicleManufacturer: undefined as IVehicleManufacturer | undefined,

      tab: 0,
      tabs: [this.$t('vehicle').toString(), this.$t('details').toString()],

      trainingData: [] as any,
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      vehicles: 'vehicleStore/getList',
      updateVehicleError: 'companyVehicleStore/getError',
    }),
    availableSteps () {
      const steps = [0]

      if (this.vehicle && this.vehicle.manufacturer && this.vehicle.name) {
        steps.push(1)
      }

      if (steps.includes(1)) {
        steps.push(2)
      }

      return steps
    },
  },
  watch: {
    selectedVehicleManufacturer: {
      async handler () {
        if (this.selectedVehicleManufacturer) this.vehicle.manufacturer = this.selectedVehicleManufacturer._id
      },
    },
    vehicleManufacturerSearch: {
      async handler () {
        this.vehicleManufacturerDebounce = debounceInput(
          this.vehicleManufacturerDebounce,
          this.getAllVehicleManufacturersWithVDataTable,
        )
      },
    },
  },
  created () {},
  mounted () {
    if (this.selectedVehicle) {
      if (!this.selectedVehicle.details) {
        this.selectedVehicle.details = { content: '', pdfs: [] }
      } else {
        this.vehicleDetails = this.selectedVehicle.details
      }
      this.trainingData = this.selectedVehicle.details.pdfs
      this.vehicle = this.selectedVehicle
    }
  },
  methods: {
    ...mapActions('vehicleStore', {
      createVehicle: 'createItem',
      updateVehicle: 'updateItem',
      deleteVehicle: 'deleteItem',
    }),
    ...mapActions('vehicleStore', {
      createVehicle: 'createItem',
    }),
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        await this.setTrainingData()
        await this.saveVehicle()
        this.wizardFinished()
      } else {
        this.tab++
      }
    },
    async saveVehicle () {
      this.loading = true
      this.vehicle.details = this.vehicleDetails
      if (this.vehicle._id) {
        await this.updateVehicle({
          id: this.vehicle._id,
          item: this.vehicle,
        })
      } else {
        await this.createVehicle({ item: this.vehicle }).then(() => {
          this.dialog = false
        })
      }
      this.dialog = false

      if (this.updateVehicleError) {
        this.$toast.error(this.$t('dialog.save-vehicle-error').toString())
      } else {
        this.$toast(this.$t('dialog.save-vehicle-success').toString())
      }
    },
    wizardFinished () {
      if (this.updateVehicleError) {
        if (this.updateVehicleError.response && this.updateVehicleError.response.status === 400) {
          this.$toast.error(this.$t('dialog.vehicle-already-exists').toString())
        } else {
          this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
        }
      } else {
        this.$toast(this.$t('dialog.vehicle-save-successfully').toString())
        this.$emit('wizardfinished')
      }
    },
    async getAllVehicleManufacturersWithVDataTable () {
      const query = {
        l: 20,
        lo: 1,
        sf: 'name',
        so: 'asc',
        search: { search: this.vehicleManufacturerSearch },
      }
      vehicleService.getVehicleManufacturs(this.$store, query).then((result) => {
        this.vehicleManufacturers = result.items
      })
    },
    autocompleteFilter (item: any, queryText: string, itemText: string) {
      return item.name.toLowerCase().includes(queryText.toLowerCase())
    },
    async setTrainingData () {
      this.vehicleDetails.pdfs = []
      if (this.trainingData && this.trainingData.length) {
        for (const file of this.trainingData) {
          const name = file.name
          if (file instanceof Blob) {
            const base64 = await this.toBase64(file)
            this.vehicleDetails.pdfs.push({ name: name, file: base64 })
          } else {
            this.vehicleDetails.pdfs.push(file)
          }
        }
      }
    },

    deleteTrainingData (index: number) {
      setTimeout(() => {
        this.trainingData.splice(index, 1)
      }, 0)
    },
    toBase64 (file: any) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
  },
})
